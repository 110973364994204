import React from 'react'; // eslint-disable-line no-unused-vars
import Layout from '../components/Layout';
import { Paragraph, Title } from '../components/Texts';

const Services = () => (
  <Layout title="Services">
    <Title>
      Services
    </Title>

    <Title level={2} sx={{ mt: 5 }}>Analyse et traitement de données</Title>

    <Title level={2} sx={{ mt: 5 }}>Ingénierie logicielle</Title>

    <Title level={3}>Développement de logiciels</Title>
    <Paragraph>
      Qu’il s’agisse de coder un projet ex nihilo, optimiser un logiciel existant ou terminer un projet bloqué, notre équipe travaille avec vous pour créer un code rationalisé et réutilisable. Nos développeurs sont des experts en Python et JavaScript. Ils écriront le logiciel en utilisant la plateforme de programmation la plus appropriée à votre projet.<br />
      Nous pouvons particulièrement appliquer des méthodes d’ingénierie logicielles à des applications développées de façon empirique dans le cadre de projets de recherche.
    </Paragraph>

    <Title level={3}>Continuité de projets</Title>
    <Paragraph>
      Les logiciels personnalisés créés pour vos besoins internes peuvent avoir un long cycle de vie, nécessitant des mises à jour et des modifications au fur et à mesure de l’avancement de vos projets.<br />
      Un logiciel parfaitement fonctionnel lors de sa conception peut devenir inutilisable en quelques mois en raison de l’évolution des bibliothèques et des environnements.<br />
      Les auteurs internes de logiciels peuvent changer de sujet et ne plus maintenir leur code. Avoir recours à nos développeurs professionnels qui vous accompagneront pendant toute la durée du projet permet de contrebalancer les effets de la rotation des membres de votre équipe.
    </Paragraph>

    <Title level={3}>Publication de logiciels libres</Title>
    <Paragraph>
      Nos équipes vous assistent pour transformer vos applications en logiciels libres.<br />
      Afin que votre logiciel soit utilisé et diffusé, nous vous aiderons à aller bien au-delà de la simple publication du code source en organisant de nombreuses activités&nbsp;: Communiquer autour du code (expliciter les règle de contribution, l’organisation de la maintenance, fournir une documentation, gérer les versions du logiciel…), Fournir une démonstration, Impliquer les utilisateurs et les contributeurs, Suivre activement les bugs, Fournir des méthodes d’installation faciles, Fournir une suite de tests…
    </Paragraph>

    <Title level={3}>Maintenance</Title>
    <Paragraph>
      Même un logiciel complet et performant nécessite une maintenance de base permanente. Mak Corp peut vous aider à maintenir vos serveurs en état de marche et à assurer la disponibilité de vos applications.
    </Paragraph>

    <Title level={3}>Création de sites Web</Title>
    <Paragraph>
      Donnez accès à vos travaux et mettez vos données à disposition en ligne via un site Web sécurisé et bien conçu. Faites la promotion de votre structure, de vos projets en cours et mettez en avant les réalisations des membres de votre équipe.
    </Paragraph>

    <Title level={2} sx={{ mt: 5 }}>Projets de recherche collaborative</Title>

    <Title level={3}>Projets de recherche collaborative</Title>
    <Paragraph>
      Mak Corp peut apporter son savoir-faire en tant que partenaire entreprise de projets de recherche collaboratifs.<br />
      Comme l’IRIT, le LIUPPA, l’IJA, le Laboratoire Environnement Ville société… faites confiance à notre équipe&nbsp;!
    </Paragraph>

    <Title level={3}>Opérations de recherche pour entreprises</Title>
    <Paragraph>
      Mak Corp bénéficie d’un Agrément Recherche du ministère de l’Enseignement supérieur, de la Recherche et de l’Innovation.<br />
      Cet agrément est la reconnaissance de nos compétences en termes de recherche et développement.<br />
      Dépenses éligibles au Crédit d’Impôt Recherche&nbsp;: les clients privés de Mak Corp peuvent inclure les opérations de Recherche & Développement éligibles réalisées par la société dans l’assiette de leur Crédit d’Impôt Recherche (CIR).
    </Paragraph>

  </Layout>
);

export default Services;
